import useStyles from "./styles";
import { Button } from "@material-ui/core";
import { ColorBox } from "../../../../shared/components";
import { colors } from "../../../../shared/utils";
import { endoso } from "../../../../services";
import { useHistory } from "react-router";
import { Fragment, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/context";
import { EndosoContext } from "pages/endoso/context";
import CryptoJS from "crypto-js";

const FooterSection = ({
  submit,
  setSpinner,
  showButtons,
  spinner,
  formDta,
  setShowButtons,
  setMessage,
  setShowMessage,
  data,
  dataSend,
  setDataContractor,
  suplemLogico,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { finishR21 } = useContext(EndosoContext);
  const { setPersons, confirmData, setConfirmData, setTimer, showContinue, setHandleContinue, setShowBack } = useContext(AuthContext);
  const key = process.env.REACT_APP_URL_SECRET_KEY;
  const [haveMail, setHaveMail] = useState(false);

  /* methods */
  const handleCancelRequest = async () => {
    setTimer(0);
    setSpinner(true);
    await endoso.cancelRequest(formDta.noSolicitud);
    setSpinner(false);
    setPersons([]);
    history.push("/");
  };
  const recoverData = async () => {
    setTimer(0);
    setSpinner(true);
    const configBody = {
      solicitud: formDta.noSolicitud,
      nivel: formDta.nivel,
      llave: formDta.llave ?? null,
      idPersona: null,
      idDomicilio: null,
    };
    const searchResponse = await endoso.configuration(configBody);
    if (searchResponse.data?.codigo === 1) {
      formDta.datosEndoso = searchResponse.data.datosEndoso;
      setShowButtons({ ...showButtons, infoTable: false });
    } else {
      setMessage({
        text: "SU SOLICITUD NO HA PODIDO SER PROCESADA",
        severity: "warning",
      });
      setShowMessage(true);
    }
    setSpinner(false);
  };

  const getUsernameFromToken = () => {
    const tokenData = localStorage.getItem("token");

    if (tokenData) {
      try {
        const parsedToken = JSON.parse(tokenData);
        return parsedToken.username || null;
      } catch (error) {
        console.error("Error al parsear el token:", error);
        return null;
      }
    }

    return null;
  };

  const encryptUrl = (url) => {
    return CryptoJS.AES.encrypt(
      url,
      CryptoJS.enc.Utf8.parse(key),
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }
    ).toString();
  };

  const sendEncryptedDocumentsEmail = async (confirmResponse, formDta, data) => {
    setSpinner(true);
    const correoContratante = confirmResponse.data.correoContratante;
    if (correoContratante) {
      setMessage({
        text: "El endoso ha sido confirmado de manera satisfactoria. Has sido copiado en el correo que hemos enviado al contratante de la póliza con la documentación correspondiente al endoso realizado",
        severity: "success",
        action: true,
      });
    }

    const encryptedDocuments = confirmResponse.data?.documentos.map((doc) => ({
      ...doc,
      url: encryptUrl(doc.url),
    }));

    if (confirmResponse.data.correoContratante) {
      const resp = await endoso.sendDocumentsEmail({
        idSolicitud: formDta.noSolicitud,
        oficina: data.oficina,
        poliza: data.poliza,
        ramo: data.ramo,
        suplemento: suplemLogico ?? formDta.suplemLogico ?? 0,
        codigoEndoso: data.tipend,
        descripcionEndoso: data.descripcionEndoso,
        correoAgente: getUsernameFromToken(),
        correoContratante,
        documentos: encryptedDocuments,
      });

      if ((resp.data?.codigo === 1 || resp.data?.codigo === '1')) {
        setShowMessage(true);
        setShowButtons({ ...showButtons, sendEmail: true });
        setShowMessage(true);
        setConfirmData(confirmResponse.data || {});
        setHaveMail(true);
        setShowBack(true);
        setSpinner(false);
      }
    } else {
      setShowButtons({ ...showButtons, sendEmail: true });
      setMessage({
        text: "OCURRIO UN PROBLEMA CON EL ENVIO DE CORREOS",
        severity: "warning",
      });
      setShowMessage(true);
      setConfirmData(confirmResponse.data || {});
      setHaveMail(false);
    }
    setSpinner(false);
  };

  useEffect(() => {
    if (showButtons.sendEmail || showButtons.formEmail) {
      setShowBack(false);
    }
  }, [showButtons, setShowBack]); // eslint-disable-line

  const confirmRequest = async () => {
    setTimer(0);
    setSpinner(true);
    setHandleContinue(false)
    if (showButtons.infoTable && data.tipend <= 20) {
      if (data.tipend === 8 || data.tipend === 12) {
        const tarifaResponse = await endoso.tarifa({
          solicitud: formDta.noSolicitud,
        });
        if (tarifaResponse.data?.codigo === 1) {
          const body = {
            idSolicitud: formDta.noSolicitud,
            oficina: data.oficina,
            poliza: data.poliza,
            ramo: data.ramo,
            suplemento: formDta.suplemLogico,
            cdTipo: data.tipend >= 21 && data.tipend <= 23 ? 'P' : 'PE',
          };

          const confirmResponse = await endoso.confirma(body);
          if (confirmResponse.data?.codigo === 1) {
            sendEncryptedDocumentsEmail(confirmResponse, formDta, data);
            if (dataSend) {
              setDataContractor(dataSend);
            }
          } else {
            setMessage({
              text: "OCURRIO UN PROBLEMA CON SU SOLICITUD",
              severity: "warning",
            });
            setShowMessage(true);
            const timer = setTimeout(() => history.push("/"), 2000);
            return () => clearTimeout(timer);
          }
        } else {
          setMessage({
            text: "OCURRIO UN PROBLEMA CON SU SOLICITUD",
            severity: "warning",
          });
          setShowMessage(true);
        }
      } else {
        const body = {
          idSolicitud: formDta.noSolicitud,
          oficina: data.oficina,
          poliza: data.poliza,
          ramo: data.ramo,
          suplemento: formDta.suplemLogico,
          cdTipo: data.tipend >= 21 && data.tipend <= 23 ? 'P' : 'PE',
        };

        const confirmResponse = await endoso.confirma(body);
        if (confirmResponse.data?.codigo === 1) {
          sendEncryptedDocumentsEmail(confirmResponse, formDta, data);
        } else {
          setMessage({
            text: "OCURRIO UN PROBLEMA CON SU SOLICITUD",
            severity: "warning",
          });
          setShowMessage(true);
          const timer = setTimeout(() => history.push("/"), 2000);
          return () => clearTimeout(timer);
        }
      }
    } else if (showButtons.multiTable || (showButtons.infoTable && data.tipend >= 21)) {
      const tarifaResponse = await endoso.tarifa({
        solicitud: formDta.noSolicitud,
      });
      if (tarifaResponse.data?.codigo === 1) {
        const body = {
          idSolicitud: formDta.noSolicitud,
          oficina: data.oficina,
          poliza: data.poliza,
          ramo: data.ramo,
          suplemento: formDta.suplemLogico,
          cdTipo: data.tipend >= 21 && data.tipend <= 23 ? 'P' : 'PE',
        };

        const confirmResponse = await endoso.confirma(body);
        setSpinner(true);
        if (confirmResponse.data?.codigo === 1) {
          sendEncryptedDocumentsEmail(confirmResponse, formDta, data);
        } else {
          setMessage({
            text: "OCURRIO UN PROBLEMA CON SU SOLICITUD",
            severity: "warning",
          });
          setShowMessage(true);
          const timer = setTimeout(() => history.push("/"), 2000);
          return () => clearTimeout(timer);
        }
      } else {
        setMessage({
          text:
            tarifaResponse.data?.mensaje ||
            "OCURRIO UN PROBLEMA CON SU SOLICITUD",
          severity: "warning",
        });
        setShowMessage(true);
      }
    } else {
      await submit();
    }
  };
  const handleSendEmail = async () => {
    setTimer(0);
    if (haveMail) {
      const timer = setTimeout(() => history.push("/"), 2000);
      return () => clearTimeout(timer);
    } else {
      const encryptedDocuments = confirmData.documentos?.map((doc) => ({
        ...doc,
        url: encryptUrl(doc.url),
      }));

      if (showButtons.formEmail) {
        setSpinner(true);
        const body = {
          idSolicitud: formDta.noSolicitud,
          oficina: data.oficina,
          poliza: data.poliza,
          ramo: data.ramo,
          suplemento: formDta.suplemLogico,
          codigoEndoso: data.tipend,
          descripcionEndoso: data.descripcionEndoso,
          correoAgente: getUsernameFromToken(),
          correoContratante: confirmData.emailData.emailOne,
          documentos: encryptedDocuments,
        };

        const sendDocuments = await endoso.sendDocumentsEmail(body);

        if (sendDocuments.data?.codigo === 1) {
          setMessage({
            text: "SE HA REALIZADO SU SOLICITUD SATISFACTORIAMENTE",
            severity: "success",
          });
          setShowMessage(true);
          const timer = setTimeout(() => history.push("/"), 2000);
          return () => clearTimeout(timer);
        } else {
          setMessage({
            text:
              sendDocuments.data?.mensaje ||
              "OCURRIO UN PROBLEMA CON SU SOLICITUD",
            severity: "warning",
          });
          setShowMessage(true);
        }

        setSpinner(false);
      } else {
        if ((confirmData.documents && confirmData.documents.length > 0) || (confirmData.documentos && confirmData.documentos.length > 0)) {
          setShowButtons({ ...showButtons, formEmail: true });
        } else {
          setMessage({
            text: "DEBE SELECCIONAR AL MENOS UN DOCUMENTO",
            severity: "warning",
          });
          setShowMessage(true);
        }
      }
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <ColorBox color={colors.blueSoft} legend="Vigente" />

        <ColorBox color={colors.redSpecial} legend="Especial" />
      </div>
      <div className={classes.actions}>
        {!showButtons.sendEmail ? (
          <Fragment>
            {showButtons.infoTable &&
              data.tipend !== 8 &&
              data.tipend !== 6 &&
              data.tipend !== 7 &&
              data.tipend !== 12 &&
              data.tipend !== 16 &&
              data.tipend !== 20 &&
              data.tipend !== 21 &&
              data.tipend !== 22 &&
              data.tipend !== 23 && (
                <Button
                  variant="text"
                  className={classes.button}
                  onClick={recoverData}
                  disabled={spinner}
                >
                  <strong>Modificar</strong>
                </Button>
              )}
            <Button
              variant="text"
              className={classes.button}
              onClick={handleCancelRequest}
              disabled={spinner || !formDta.nivel}
            >
              <strong>Cancelar</strong>
            </Button>
            {showButtons.guardarEndoso && !formDta.mensaje && (
              <Button
                variant="text"
                className={classes.button}
                onClick={confirmRequest}
                disabled={
                  spinner ||
                  !formDta.nivel ||
                  (showContinue && (data.tipend === 16 || data.tipend === 14)) ||
                  (data.tipend === 6 && showButtons.disableSix) ||
                  (data.tipend === 21 && Object.keys(finishR21).length === 0) ||
                  (data.tipend === 22 && showButtons.disableTwentyTwo) ||
                  (data.tipend === 23 && showButtons.disableTwentyThree)
                }
              >
                {showButtons.infoTable || showButtons.multiTable ? (
                  <strong>Confirmar Endoso</strong>
                ) : (
                  <strong>Guardar Borrador</strong>
                )}
              </Button>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <Button
              variant="text"
              className={classes.button}
              disabled={true}
              onClick={() => {
                setTimer(0);
                setConfirmData({});
                history.push("/");
              }}
            >
              <strong>Cancelar</strong>
            </Button>
            <Button
              variant="text"
              className={classes.button}
              onClick={handleSendEmail}
              disabled={showButtons.disableSend || spinner}
            >
              <strong>{haveMail ? "Finalizar" : (showButtons.formEmail ? "Enviar" : "Notificar")}</strong>
            </Button>
          </Fragment>
        )}
      </div>
    </div >
  );
};

export default FooterSection;
